import React, { useEffect } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import Footer from "../components/Context/Footer";
import HeroInsightTagListing from "../components/General/HeroInsightTagListing";
import TagInsightsBlock from "../components/Insights/TagInsightsBlock";
import CTA from "../components/General/CTA";

import { graphql } from "gatsby";
import usePreviewData from "../util/usePreviewData";
import { useInsightsStoreContext } from "../components/Insights/InsightsStore";
import TagsList from "../components/Insights/TagsList";

const InsightTagListing = ({ data: staticData, pageContext }) => {
    const data = usePreviewData(staticData);
    const {title, meta_description, share_image, heading_promo, teaser, button_cta, link_promo} = data.prismicInsightsLanding.data;
    const articlePlaceholderImage = data.file.childImageSharp.fluid;

    const store = useInsightsStoreContext();
    const key = `insights/tag-${pageContext.slug}`;
    useEffect(() => {
        if(store.isInitializing(key)) {
            store.updateState({
                [`${key}/${pageContext.currentPage}`]: pageContext.data,
                [`${key}/cursor`]: pageContext.currentPage + 1
            })
        }
    }, [pageContext, key])

    const theme = "dark";

    return (
        <Layout>
            <SEO title={title.text} description={meta_description} image={share_image.url} />
            <Header theme={theme} />
            <HeroInsightTagListing
                title={pageContext.tag}
                theme={theme}
            />
            <TagInsightsBlock
                theme={theme}
                bg="insights blue"
                store={store}
                typeKey={key}
                currentPage={pageContext.currentPage}
                totalPages={pageContext.numPages}
                fallbackImage={articlePlaceholderImage}
            />
            <TagsList
                tags={pageContext.tags}
                bg="insights blue"
                theme={theme}
                sectionSibling={true}
                topSpacing={true}
            />
            <CTA
                theme={theme}
                title={heading_promo.text}
                content={teaser.html}
                buttonText={button_cta}
                buttonUrl={link_promo.url}
                buttonInternal={true}
            />
            <Footer theme={theme} />
        </Layout>
    );
};

export const pageQuery = graphql`
    query insightTagArticlesQuery {
        prismicInsightsLanding {
            data {
                title {
                    text
                }
                heading_promo {
                    text
                }
                teaser {
                    html
                }
                button_cta
                link_promo {
                    url
                    target
                    link_type
                }
                meta_description
                share_image {
                    url
                }
            }
        }
        file(name: {eq: "placeholder-insights-image"}) {
            childImageSharp {
                fluid(maxWidth: 2250) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`;

export default InsightTagListing;
