import React from "react";
import styled from "@emotion/styled";
import Container from "../Context/Container";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import Section from "../Context/Section";
import FadeUp from "../Effects/FadeUp";
import CustomLink from "../Elements/CustomLink";
import ChevronLeftThinSvg from "../SVGs/ChevronLeftThinSvg";

const BackLink = styled.div`
    width: 100%;
    text-align: center;
    display: inline-block;
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
        text-align: left;
        margin-bottom: 42px;
    }

    a {
        color: ${color("dark")};
        text-decoration: none;
        ${typography.label};
        transition: color 0.2s;

        svg {
            stroke: ${color("dark")};
            height: 0.8em;
            position: relative;
            top: 1px;
            margin-right: 11px;
            transition: stroke 0.2s;
        }

        &:hover {
            color: ${color("dark blue")};

            svg {
                stroke: ${color("dark blue")};
            }
        }
    }
`;

const Title = styled.h1`
    ${typography.h2};
    color: ${color("dark")};
    width: 100%;
    text-align: center;
    display: inline-block;
    margin-bottom: 8px;

    @media screen and (min-width: 768px) {
        margin-bottom: 10px;
        text-align: left;
        max-width: 882px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 16px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 15px;
    }
`;

const PageIntro = styled.div`
    ${typography.body1};
    ${typography.RTESpacing};
    text-align: center;
    color: ${color("dark")};

    a{
        color: ${color("dark")};
        transition: color 0.2s ease;
        text-decoration: none;

        &:hover{
            color: ${color("dark blue")};
        }
    }

    @media screen and (min-width: 768px) {
        max-width: 100%;
        text-align: left;
    }

    @media screen and (min-width: 1200px) {
        max-width: 660px;
    }

    @media screen and (min-width: 1800px) {
        max-width: 987px;
    }
`;

const HeroInsightTagListing = ({title, subtitle, theme}) => {
    return (
        <Section paddingS="180px 0 64px" minHeightS="0" paddingM="200px 0 64px" minHeightM="0" paddingL="225px 0 64px" minHeightL="0" paddingXL="248px 0 64px" minHeightXL="0" paddingXXL="248px 0 64px" minHeightXXL="0" bg="white" fg="dark" theme={theme} flexGrow="1">
            <Container type="hero">
                <FadeUp time="2s">
                    <BackLink>
                        <CustomLink url="/insights" internal={true}>
                            <ChevronLeftThinSvg />Back to <strong>Insights</strong>
                        </CustomLink>
                    </BackLink>
                    <Title>
                        {title}
                    </Title>
                </FadeUp>
                {subtitle ? (
                    <FadeUp time="2s" delay="0.4s">
                        <PageIntro dangerouslySetInnerHTML={{ __html: subtitle }} />
                    </FadeUp>
                ) : null}
            </Container>
        </Section>
    );
};

export default HeroInsightTagListing;
